<template>
  <div :id="loginType" class="w-full" :class="styleClass">
    <button
      class="bg-white h-12 w-full gap-20px flex items-center justify-center hover:bg-accent-orange-hover transition-all ease-in-out duration-200"
      @click.prevent="callSocialLogin"
    >
      <img :src="button.icon" :alt="button.alt" />
      <!-- <i :class="`icon-${button.icon}`" class="text-black" /> -->
      <span class="text-grayscale-54 font-medium">Sign in with Google</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { SocialLoginTypes } from "@/types/authentication";

interface ButtonType {
  icon: string;
  alt: string;
  type: SocialLoginTypes;
}

const props = defineProps({
  loginType: {
    type: String,
  },
  styleClass: {
    type: String,
  },
});
const emit = defineEmits(["onSocialClick"]);

const buttons: ButtonType[] = [
  {
    icon: "https://ik.imagekit.io/xposuremusic/google.svg",
    alt: "Google Icon",
    type: SocialLoginTypes.google,
  },
  {
    icon: "twitter",
    alt: "Twitter Icon",
    type: SocialLoginTypes.twitter,
  },
];
const button: ButtonType = buttons.find(
  (button: ButtonType) => button.type === props.loginType,
) || { icon: "", alt: "", type: SocialLoginTypes.google };
const errorMessage = ref("");

function callSocialLogin() {
  emit("onSocialClick", props.loginType);
}
</script>
